import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo 1.png';
import './Navbar.css'

const Navbar = () => {
  // useEffect(() => {
  //   let menuChange = document.querySelectorAll('.linkChange');
  //   menuChange.forEach(mc => {
  //     mc.addEventListener('click', function () {
  //       menuChange.forEach(lnk => lnk.classList.remove('change'))
  //       this.classList.add('change')
  //     })
  //   })
  // }, [])


  const menuItems =
    <>


    </>


  return (

      
        <nav id='navbar' class="navbar navbar-expand-lg bg-dark sticky-top fixed-top">
        <div class="container">
          <a class="navbar-brand text-white  d-flex" href="#banner"> <Link id='logo' to='/'><img src={logo} alt="" className='real h-6 w-6 linkChange' /></Link>&nbsp;&nbsp;Tasty</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mb-2 mb-lg-0 nav-links change">

              <li class="nav-item"><a className='text-white text-decoration-none nav-link active' href='#banner'>Home </a></li>
              <li class="nav-item"><a className='text-white text-decoration-none nav-link active' href='#about'>About</a></li>
              <li class="nav-item"><a className='text-white text-decoration-none nav-link active' href='#premium'>Menu</a></li>
              <li class="nav-item"><a className='text-white text-decoration-none nav-link active' href='#new'>New </a></li>
              <li class="nav-item"><button type="button" className='navColor'><a className='text-white text-decoration-none nav-link active' to=''>Buy Now</a></button></li>


            </ul>

          </div>
        </div>
      </nav>
  );
};

export default Navbar;