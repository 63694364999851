import React, { useEffect, useState } from 'react';
import darkLight from '../images/darkLight.png';

const Footer = () => {

    const [theme, setTheme] = useState('light-them');
    const [bgCards, setBgCards] = useState('');
    const [colorSet,setColorSet] = useState('');
    const toggleTheme = () =>{
        if(theme === 'dark-theme'){
            setTheme('light-theme')
            setColorSet('')
        }else{
            setTheme('dark-theme')
            // document.getElementsByClassName("bgCard").classList.add('changeCard');
            // bgCards.forEach(mc => {
            //         this.classList.add('changeCard')
            //       })
            setColorSet('changeCard');
        }
          
    }

    useEffect(()=>{
        document.body.className = theme;
        // const menuChange = document.getElementsByClassName('bgCard');
        // setBgCards(menuChange);
        // document.getElementById("myID").className = colorSet;
    },[theme])
    console.log(theme);
    console.log(colorSet);
    return (
            <footer className="text-center text-lg-start text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div>
            <a href className="me-4 text-reset">
              <i className="fab fa-facebook-f" />
            </a>
            <a href className="me-4 text-reset">
              <i className="fab fa-twitter" />
            </a>
            <a href className="me-4 text-reset">
              <i className="fab fa-google" />
            </a>
            <a href className="me-4 text-reset">
              <i className="fab fa-instagram" />
            </a>
            <a href className="me-4 text-reset">
              <i className="fab fa-linkedin" />
            </a>
            <a href className="me-4 text-reset">
              <i className="fab fa-github" />
            </a>
          </div>
        </section>
        <section className>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3" />Company name
                </h6>
                <p>
                  Here you can use rows and columns to organize your footer content. Lorem ipsum
                  dolor sit amet, consectetur adipisicing elit.
                </p>
                <button onClick={()=>toggleTheme()}>dark/light
                    <img className='bg-white rounded-xl' src={darkLight} alt="" />
                </button>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Products
                </h6>
                <p>
                  <a href="#!" className="text-reset">Bargur</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Pizza</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Burhani</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Grill</a>
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Useful links
                </h6>
                <p>
                  <a href="#!" className="text-reset">Pricing</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Settings</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Orders</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Help</a>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p><i className="fas fa-home me-3" /> New York, NY 10012, US</p>
                <p>
                  <i className="fas fa-envelope me-3" />
                  info@example.com
                </p>
                <p><i className="fas fa-phone me-3" /> + 01 234 567 88</p>
                <p><i className="fas fa-print me-3" /> + 01 234 567 89</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
          © 2022 Copyright:
          <a className="text-reset fw-bold" href="https://zerodevs.com/contact" target='_blank'>&nbsp;zerodevs.com</a>
        </div>
      </footer>
    );
};

export default Footer;