import React from 'react';
import './NewArrival.css';
import new1 from '../images/new1-img 2.png';

const NewArrival = () => {
    return (
        <div id='new' className='container'>
            <p className=' text-5xl text-center mb-3 p-5'>New Arrivals</p>
            <div className='row g-4'>
                <div class="col-lg-4 mb-4">
                    <i className='d-flex justify-content-start'>
                        <button className='newBtn'>New</button>
                    </i>
                    <div class="boxStyle shadow-md rounded ">
                        <i className='grid place-items-center '>
                            <img className=' rounded-lg h-40' src={new1} alt="" />
                        </i>
                        <div class="card-body">
                            <h5 class="text-center">good food testy</h5>
                            <p class="text-center">good for health</p>
                            <small class="text-center">5654656</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4 ">
                    <i className='d-flex justify-content-start'>
                        <button className='newBtn'>New</button>
                    </i>
                    <div class="boxStyle shadow-md rounded">
                        <i className='grid place-items-center '>
                            <img className=' rounded-lg h-40' src={new1} alt="" />
                        </i>
                        <div class="card-body">
                            <h5 class="text-center">good food testy</h5>
                            <p class="text-center">good for health</p>
                            <small class="text-center">5654656</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4 ">
                    <i className='d-flex justify-content-start'>
                        <button className='newBtn'>New</button>
                    </i>
                    <div class="boxStyle shadow-md rounded">
                        <i className='grid place-items-center '>
                            <img className=' rounded-lg h-40' src={new1} alt="" />
                        </i>
                        <div class="card-body">
                            <h5 class="text-center">good food testy</h5>
                            <p class="text-center">good for health</p>
                            <small class="text-center">5654656</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewArrival;