import React from 'react';
import './Banner.css';
import homeImage from '../images/home1-img 1.png';

const Banner = () => {
    return (
        <div id='banner' className='container mt-2'>
            <div className='row'>
                <div class=" col-lg-6 mb-4 grid place-items-center">
                    <img className=' rounded-lg h-96 w-96' src={homeImage} alt="" />
                    <div className='text-right '>
                        <p>Fish salad</p>
                        <p>Fish Salad and sweet</p>
                        <p>Potatos and Basi</p>
                    </div>
                </div>

                <div class=" col-lg-6 mb-4 grid place-items-center">
                   <div className=' text-left'>
                   <p className='text-xl uppercase'>#1 of this week</p>
                   <p className='text-6xl uppercase leading-none w-72 h-72'>eat healthy be healthy</p>
                   <p className=' '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime obcaecati earum fugiat architecto maiores magnam, quibusdam incidunt hic dolor  quibusdam incidunt hic dolor</p>
                   <p className=''><button className='btnColor'>Order Now</button></p>
                   </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;