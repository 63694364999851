import Banner from "./components/Banner/Banner";
import Discount from "./components/Discount/Discount";
import Hero from "./components/Hero/Hero";
import NewArrival from "./components/NewArrival/NewArrival";
import Premium from "./components/Premium/Premium";
import Service from "./components/Service/Service";
import Navbar from "./components/Shear/Navbar";
import './App.css';
import Footer from "./components/Footer/Footer";


function App() {
  return (
    <div className="">
      <Navbar></Navbar>
      <Banner></Banner>
      <Service></Service>
      <Hero></Hero>
      <Premium></Premium>
      <Discount></Discount>
      <NewArrival></NewArrival>
      <Footer></Footer>
    </div>
  );
}

export default App;
