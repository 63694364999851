import React from 'react';
import discount from '../images/discount-img 1.png';

const Discount = () => {
    return (
        <section>
            <div className='container mt-2'>
            <div className='row p-20'>
                <div class=" col-lg-6 mb-4 grid place-items-center">
                    <img className=' rounded-lg h-52 w-52' src={discount} alt="" />
                </div>

                <div class=" col-lg-6 mb-4 grid place-items-center">
                   <div className=' text-left'>
                   <p className='text-2xl leading-tight w-44'>50% Discount On New Product</p>
                   <p className=''><button className='btnColor'>Order Now</button></p>
                   </div>
                </div>
            </div>
        </div>
        </section>
    );
};

export default Discount;