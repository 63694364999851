import React from 'react';
import service from '../images/customer-service 1.png';
import delivery from '../images/fast-delivery 1.png';
import food from '../images/food 1.png';

const Service = () => {
    return (
        <section className='p-5'>
            <div className='container'>
                <p className='text-center text-7xl'>Our Service</p>
                <div className='row  p-5'>
                        <div className='col-lg-3 offset-lg-1 grid place-content-center'>
                            <img src={service} alt="" className='h-40' />
                            <div className=' text-center'>
                                <h4>24/7 Services</h4>
                                <p>lorem sjfidjf sdn</p>
                                <small>testy food</small>
                            </div>
                        </div>
                        <div className='col-lg-3 grid place-content-center'>
                            <img src={delivery} alt="" className='h-40' />
                            <div className=' text-center'>
                                <h4>Fast Delivery</h4>
                                <p>lorem sjfidjf sdn</p>
                                <small>testy food</small>
                            </div>
                        </div>
                        <div className='col-lg-3 grid place-content-center'>
                            <img src={food} alt="" className='h-40' />
                            <div className=' text-center'>
                                <h4>delicious food</h4>
                                <p>lorem sjfidjf sdn</p>
                                <small>testy food</small>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    );
};

export default Service;