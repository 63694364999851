import React, { useEffect } from 'react';
import premium1 from '../images/menu-2 1.png';
import premium2 from '../images/menu-3 1.png'
import premium3 from '../images/menu-4 1.png'
import premium5 from '../images/menu-5 1.png'
import premium6 from '../images/menu-6 1.png'
import premium7 from '../images/menu-6 2.png'

const Premium = () => {
  
  return (
    <div id='premium' className='container'>
        <p className=' text-5xl text-center mb-3 p-5'>Premium Menu</p>
      <div className='row'>
        
        <div data-aos="fade-up" class="col-lg-3 mb-4 offset-lg-1">
          <div id='myID' class="shadow-md rounded bgCard">
            <i className='grid place-items-center '>
              <img className=' rounded-lg h-40' src={premium1} alt="" />
            </i>
            <div class="card-body">
              <h5 class="text-center">Hamburger</h5>
              <p class="text-center">Fast Food</p>
              <small class="text-center">$8.99</small>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-lg-3 mb-4">
          <div id='myID' class=" shadow-md rounded bgCard">
            <i className='grid place-items-center '>
              <img className=' rounded-lg h-40' src={premium2} alt="" />
            </i>
            <div class="card-body">
              <h5 class="text-center">Hamburger</h5>
              <p class="text-center">Fast Food</p>
              <small class="text-center">$8.99</small>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-lg-3 mb-4">
          <div id='myID' class=" shadow-md rounded bgCard">
            <i className='grid place-items-center '>
              <img className=' rounded-lg h-40' src={premium3} alt="" />
            </i>
            <div class="card-body">
              <h5 class="text-center">Spaghetti</h5>
              <p class="text-center">Healthy Food</p>
              <small class="text-center">$15.99</small>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-lg-3 mb-4 offset-lg-1">
          <div id='myID' class=" shadow-md rounded bgCard">
            <i className='grid place-items-center '>
              <img className=' rounded-lg h-40' src={premium5} alt="" />
            </i>
            <div class="card-body">
              <h5 class="text-center">Pepperoni Pizza</h5>
              <p class="text-center">Fast Food</p>
              <small class="text-center">$7.99</small>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-lg-3 mb-4">
          <div id='myID' class=" shadow-md rounded bgCard">
            <i className='grid place-items-center '>
              <img className=' rounded-lg h-40' src={premium6} alt="" />
            </i>
            <div class="card-body">
              <h5 class="text-center">Fish Salad</h5>
              <p class="text-center">Healthy Food</p>
              <small class="text-center">$19.99</small>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="col-lg-3 mb-4">
          <div id='myID' class=" shadow-md rounded bgCard">
            <i className='grid place-items-center '>
              <img className=' rounded-lg h-40' src={premium7} alt="" />
            </i>
            <div class="card-body">
              <h5 class="text-center">Neapoliton Pizza</h5>
              <p class="text-center">Fast Food</p>
              <small class="text-center">$17.99</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Premium;