import React from 'react';
import './Hero.css';
import aboutPic from '../images/about-img 2.png';

const Hero = () => {
    return (
        <div id='about' class="container mt-20">
                <div class="row about-extra hero  place-content-around">
                   
                    <div class="col-lg-6 wow fadeInUp">
                        <p className='text-4xl'>About Us</p>
                        <p>
                            Our complete spectrum of mobile app development and web solutions help businesses to create outcome-focused solutions with enhanced agility that help them to rapidly reshape, renew and build new capabilities for their business.

                        </p>
                        <button className='btnColor'>Order Now</button>
                    </div>
                    <div data-aos="fade-up" class="col-lg-6 wow fadeInUp grid place-items-center">
                        <img src={aboutPic} class="img-fluid" alt="" />
                    </div>
                </div>
        </div>
    );
};

export default Hero;